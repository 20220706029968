var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showCustomTheme
        ? _c("div", { staticClass: "christmas" }, [
            _c("img", {
              staticClass: "chis-bg",
              attrs: { src: "/assets/images/christmas/c-bg.png" },
            }),
          ])
        : _vm._e(),
      _vm.showNav
        ? _c("main-top-bar", { attrs: { page: _vm.page } })
        : _vm._e(),
      _c(
        "keep-alive",
        [
          _vm.$route.meta.keepAlive
            ? _c("router-view", { key: _vm.$route.fullPath })
            : _vm._e(),
        ],
        1
      ),
      !_vm.$route.meta.keepAlive
        ? _c("router-view", { key: _vm.$route.fullPath })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }