import store from "@/store/index.js";
// NProgress
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

NProgress.configure({ showSpinner: false }); // NProgress Configuration

// 路由Layout
// import Layout from "@/layout/layout.vue";

import { getToken, getStbCookie } from "@/utils/auth.js";

Vue.use(VueRouter);

// about分块路由
const aboutRouters = [
  // about
  {
    path: "/about",
    component: () => import("@/views/about/about.vue"),
  },
  {
    path: "/artists",
    component: () => import("@/views/about/_artists.vue"),
  },
  {
    path: "/community-guidelines",
    component: () => import("@/views/about/community-guidelines.vue"),
  },
  {
    path: "/educators",
    component: () => import("@/views/about/_educators.vue"),
  },
  {
    path: "/faq/:id?",
    component: () => import("@/views/about/faq.vue"),
  },
  {
    path: "/feedback/:source?",
    component: () => import("@/views/about/_feedback.vue"),
  },
  {
    path: "/privacy",
    component: () => import("@/views/about/privacy.vue"),
  },
  {
    path: "/terms-of-service",
    component: () => import("@/views/about/terms-of-service.vue"),
  },
  {
    path: "/updates",
    component: () => import("@/views/about/updates.vue"),
  },
  {
    path: "/writers",
    component: () => import("@/views/about/_writers.vue"),
  },
];

// account分块路由
const accountRouters = [
  {
    path: "/account-avatar",
    component: () => import("@/views/account/account-avatar.vue"),
  },
  {
    path: "/account-change-password/:insecure?",
    component: () => import("@/views/account/account-change-password.vue"),
  },
  {
    path: "/account-delete",
    component: () => import("@/views/account/account-delete.vue"),
  },
  {
    path: "/account-email-notification",
    component: () => import("@/views/account/account-email-notification.vue"),
  },
  {
    path: "/account-orders",
    component: () => import("@/views/account/account-orders.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/views/account/account.vue"),
  },
  {
    path: "/complete-info",
    component: () => import("@/views/account/complete-info.vue"),
  },
  {
    path: "/email-active",
    component: () => import("@/views/account/email-active.vue"),
  },
  {
    path: "/forgot-mail-send-done",
    component: () => import("@/views/account/forgot-mail-send-done.vue"),
  },
  {
    path: "/forgot-new-password",
    component: () => import("@/views/account/forgot-new-password.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/account/forgot-password.vue"),
  },
  {
    path: "/invite/:code?",
    component: () => import("@/views/account/_invite.vue"),
  },
  {
    path: "/reactivate",
    component: () => import("@/views/account/_reactivate.vue"),
  },
  {
    path: "/reset-password",
    component: () => import("@/views/account/reset-password.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/account/signin.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/account/signup.vue"),
  },
  {
    path: "/cancel-email-notification/:userName?/:password?/:forFun?",
    component: () => import("@/views/account/cancel-email-notification.vue"),
  },
];

// chanllenge分块路由
const challengeRouters = [
  {
    path: "/challenge-guide",
    component: () => import("@/views/challenge/challenge-guide.vue"),
  },
  {
    path: "/challenge-info",
    component: () => import("@/views/challenge/challenge-info.vue"),
  },
  {
    path: "/challenge-unit/:unitid?",
    component: () => import("@/views/challenge/challenge-unit.vue"),
  },
  {
    path: "/challenge/:type?/:time?/:subtype?",
    name: "challenge",
    component: () => import("@/views/challenge/challenge.vue"),
  },
  {
    path: "/guides/:chapters*",
    component: () => import("@/views/challenge/guides.vue"),
  },
];

// classes分块路由
const classesRouters = [
  {
    path: "/class-assignment/:id?",
    name: "class-assignment",
    component: () => import("@/views/classes/class-assignment.vue"),
  },
  {
    path: "/class-compilation-create/:openid/:type?",
    component: () => import("@/views/classes/class-compilation-create.vue"),
  },
  {
    path: "/class-compilation-preview/:openid/:type/:id",
    component: () => import("@/views/classes/class-compilation-preview.vue"),
  },
  {
    path: "/class-compilation/:openid",
    component: () => import("@/views/classes/class-compilation.vue"),
  },
  {
    path: "/class-list",
    meta: {
      keepAlive: true,
    },
    name: "class-list",
    component: () => import("@/views/classes/class-list.vue"),
  },
  {
    path: "/class-roster/:id?",
    name: "class-roster",
    component: () => import("@/views/classes/class-roster.vue"),
  },
  {
    path: "/class-settings/:id?",
    name: "class-settings",
    component: () => import("@/views/classes/class-settings.vue"),
  },
  {
    path: "/class-submission/:id?",
    component: () => import("@/views/classes/class-submission.vue"),
  },
  {
    path: "/class/:id?",
    name: "class-detail",
    component: () => import("@/views/classes/classes.vue"),
  },
  {
    path: "/join-class",
    name: "join-class",
    component: () => import("@/views/classes/join-class.vue"),
  },
  {
    path: "/student-sign-in/:code/:username?",
    name: "student-sign-in",
    component: () => import("@/views/classes/student-sign-in.vue"),
  },
  {
    path: "/students-code/:code?",
    name: "students-code",
    component: () => import("@/views/classes/students-code.vue"),
  },
];

// editor分块路由
const editorRouters = [
  {
    path: "/comic-editor",
    name: "comic-editor",
    component: () => import("@/views/editors/comic-editor.vue"),
  },
  {
    path: "/flashfiction-editor",
    name: "flashfiction-editor",
    component: () => import("@/views/editors/flashfiction-editor.vue"),
  },
  {
    path: "/longformbook-editor",
    name: "longformbook-editor",
    component: () => import("@/views/editors/longformbook-editor.vue"),
  },
  {
    path: "/picturebook-editor",
    name: "picturebook-editor",
    component: () => import("@/views/editors/picturebook-editor.vue"),
  },
  {
    path: "/poetry-editor",
    name: "poetry-editor",
    component: () => import("@/views/editors/poetry-editor.vue"),
  },
  {
    path: "/creative-book-editor",
    name: "creative-book-editor",
    component: () => import("@/views/editors/creative-book-editor.vue"),
  },
  {
    path: "/creative-book/:slug",
    name: "creative-book",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/creative-book.vue"),
  },
];

// member分块路由
const memberRouters = [
  {
    path: "/become-member",
    name: "become-member",
    component: () => import("@/views/members/become-member.vue"),
  },
  {
    path: "/member-family-plan",
    name: "member-family-plan",
    component: () => import("@/views/members/member-family-plan.vue"),
  },
  {
    path: "/members-badge/:username?",
    component: () => import("@/views/members/members-badge.vue"),
  },
  {
    path: "/members-comic/:username?",
    component: () => import("@/views/members/members-comic.vue"),
  },
  {
    path: "/members-flash-fiction/:username?",
    component: () => import("@/views/members/members-flash-fiction.vue"),
  },
  {
    path: "/members-longform-story/:username?",
    component: () => import("@/views/members/members-longform-story.vue"),
  },
  {
    path: "/members-picture-book/:username?",
    component: () => import("@/views/members/members-picture-book.vue"),
  },
  {
    path: "/members-poetry/:username?",
    component: () => import("@/views/members/members-poetry.vue"),
  },
  {
    path: "/members/:username?",
    component: () => import("@/views/members/_members.vue"),
  },
  {
    path: "/membership-educator",
    name: "membership-educator",
    component: () => import("@/views/members/membership-educator.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/members/pricing.vue"),
  },
  {
    path: "/buy",
    name: "buy",
    component: () => import("@/views/members/buy.vue"),
  },
  {
    path: "/free-trial",
    name: "free-trial",
    component: () => import("@/views/members/free-trial.vue"),
  },
];

// pay分块路由
const payRouters = [
  {
    path: "/custom-order/:itemID?",
    component: () => import("@/views/pay/custom-order.vue"),
  },
  {
    path: "/pay-callback",
    component: () => import("@/views/pay/pay-callback.vue"),
  },
  {
    path: "/pay-cancel",
    component: () => import("@/views/pay/pay-cancel.vue"),
  },
  {
    path: "/redirect-stripe",
    component: () => import("@/views/pay/redirect-stripe.vue"),
  },
  {
    path: "/pre-order/:orderno?",
    component: () => import("@/views/pay/pre-order.vue"),
  },
  {
    path: "/po-order/:orderno",
    component: () => import("@/views/pay/po-order.vue"),
  },
];

// plan分块路由
const planRouters = [
  {
    path: "/educator-plan-class/:id?",
    component: () => import("@/views/plan/educator-plan-class.vue"),
  },
  {
    path: "/educator-plan-student/:id?",
    component: () => import("@/views/plan/educator-plan-student.vue"),
  },
  {
    path: "/educator-plan/:id?",
    component: () => import("@/views/plan/educator-plan.vue"),
  },
  {
    path: "/family-plan/:id?",
    component: () => import("@/views/plan/family-plan.vue"),
  },
];

// read分块路由
const readRouters = [
  {
    path: "/blog",
    meta: {
      keepAlive: true,
    },
    name: "blog",
    component: () => import("@/views/read/blog.vue"),
  },
  {
    path: "/blogentry/:param0?/:param1?/:share_social_params*",
    name: "blogentry",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/blogentry.vue"),
  },
  {
    path: "/chapters/:chapters+",
    component: () => import("@/views/read/chapters.vue"),
  },
  {
    path: "/comic/:param0?/:param1?/:share_social_params*",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/comic.vue"),
  },
  {
    path: "/creative-writing/:slug+",
    component: () => import("@/views/read/creative-writing.vue"),
  },
  {
    path: "/flash-fiction/:param0?/:param1?/:share_social_params*",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/flash-fiction.vue"),
  },
  {
    path: "/longform-story/:param0?/:param1?/:param2?/:share_social_params*",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/longform-story.vue"),
  },
  {
    path: "/picture-book-generate1/:slug",
    component: () => import("@/views/read/picture-book-generate1.vue"),
  },
  {
    path: "/picture-book/:param0?/:param1?/:share_social_params*",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/picture-book.vue"),
  },
  {
    path: "/poetry/:param0?/:param1?/:share_social_params*",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/poetry.vue"),
  },
  {
    path: "/read-comic",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/read-comic.vue"),
  },
  {
    path: "/read-flash-fiction",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/read-flash-fiction.vue"),
  },
  {
    path: "/read-longform-story",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/read-longform-story.vue"),
  },
  {
    path: "/read-picture-book",
    name: "read-picture-book",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/read-picture-book.vue"),
  },
  {
    path: "/read-poetry",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/read/read-poetry.vue"),
  },
  {
    path: "/books/:OldSlug?",
    component: () => import("@/views/read/books.vue"),
  },
  {
    path: "/read-book/:slug?",
    name: "read-book",
    component: () => import("@/views/read/read-book.vue"),
  },
];

// tools分块路由
const toolsRouters = [
  {
    path: "/book-creator/:bookType?/:slug?/:name?",
    component: () => import("@/views/tools/book-creator.vue"),
  },
  {
    path: "/book-transfer-tool",
    component: () => import("@/views/tools/book-transfer-tool.vue"),
  },
  {
    path: "/cart/:type?",
    name: "print",
    component: () => import("@/views/tools/cart.vue"),
  },
  {
    path: "/checkout",
    component: () => import("@/views/tools/checkout.vue"),
  },
  {
    path: "/chatbot",
    component: () => import("@/views/tools/chatbot.vue"),
  },
  {
    path: "/contact",
    component: () => import("@/views/tools/contact.vue"),
  },
  {
    path: "/embed-s/:OpenId?/:chapter?",
    component: () => import("@/views/tools/embed-s.vue"),
  },
  {
    path: "/embed/:OpenId?/:chapter?",
    name: "embed",
    component: () => import("@/views/tools/embed.vue"),
  },
  {
    path: "/fix-coord",
    component: () => import("@/views/tools/fix-coord.vue"),
  },
];

// write分块路由
const writeRouters = [
  {
    path: "/artist/:slug",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/write/artist.vue"),
  },
  {
    path: "/artshop/:slug?/:social_name?/:share_social_params*",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/write/artshop.vue"),
  },
  {
    path: "/artwork-book/:type?/:id?",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/write/artwork-book.vue"),
  },
  {
    path: "/artwork",
    meta: { keepAlive: true },
    component: () => import("@/views/write/artwork.vue"),
  },
  {
    path: "/comic-artist/:id?",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/write/comic-artist.vue"),
  },
  {
    path: "/comic-artwork",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/write/comic-artwork.vue"),
  },
  {
    path: "/start-comic/:special?",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/write/start-comic.vue"),
  },
  {
    path: "/start-with-art/:bookType/:special?",
    meta: { keepAlive: true },
    name: "start-with-art",
    component: () => import("@/views/write/start-with-art.vue"),
  },
];

//yourstuff分块路由
const yourstuffRouters = [
  {
    path: "/get-start",
    name: "get-start",
    component: () => import("@/views/yourstuff/get-start.vue"),
  },
  {
    path: "/yourstuff-artshop-settings",
    component: () => import("@/views/yourstuff/yourstuff-artshop-settings.vue"),
  },
  {
    path: "/yourstuff-artshop",
    component: () => import("@/views/yourstuff/yourstuff-artshop.vue"),
  },
  {
    path: "/yourstuff-badges",
    name: "yourstuff-badges",
    component: () => import("@/views/yourstuff/yourstuff-badges.vue"),
  },
  {
    path: "/yourstuff-class/:openId?",
    name: "yourstuff-class",
    component: () => import("@/views/yourstuff/yourstuff-class.vue"),
  },
  {
    path: "/yourstuff-comic-artwork",
    component: () => import("@/views/yourstuff/yourstuff-comic-artwork.vue"),
  },
  {
    path: "/yourstuff-crowns",
    name: "yourstuff-crowns",
    component: () => import("@/views/yourstuff/yourstuff-crowns.vue"),
  },
  {
    path: "/yourstuff-earnings-history",
    component: () => import("@/views/yourstuff/yourstuff-earnings-history.vue"),
  },
  {
    path: "/yourstuff-edit-artwork/:artworkId?",
    component: () => import("@/views/yourstuff/yourstuff-edit-artwork.vue"),
  },
  {
    path: "/yourstuff-favorites-list/:type",
    name: "yourstuff-favorites-list",
    component: () => import("@/views/yourstuff/yourstuff-favorites-list.vue"),
  },
  {
    path: "/yourstuff-favorites",
    name: "yourstuff-favorites",
    component: () => import("@/views/yourstuff/yourstuff-favorites.vue"),
  },
  {
    path: "/yourstuff-uploads",
    name: "yourstuff-uploads",
    component: () => import("@/views/yourstuff/yourstuff-uploads.vue"),
  },
  {
    path: "/yourstuff-notification",
    component: () => import("@/views/yourstuff/yourstuff-notification.vue"),
  },
  {
    path: "/yourstuff-pdfs",
    name: "yourstuff-pdfs",
    component: () => import("@/views/yourstuff/yourstuff-pdfs.vue"),
  },
  {
    path: "/yourstuff-pdfv1",
    component: () => import("@/views/yourstuff/yourstuff-pdfv1.vue"),
  },
  {
    path: "/yourstuff-print-order",
    component: () => import("@/views/yourstuff/yourstuff-print-order.vue"),
  },
  {
    path: "/yourstuff-publish",
    component: () => import("@/views/yourstuff/yourstuff-publish.vue"),
  },
  {
    path: "/yourstuff-read",
    name: "yourstuff-read",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/yourstuff/yourstuff-read.vue"),
  },
  {
    path: "/yourstuff-ticket",
    name: "yourstuff-ticket",
    component: () => import("@/views/yourstuff/yourstuff-ticket.vue"),
  },
  {
    path: "/yourstuff-tools",
    component: () => import("@/views/yourstuff/yourstuff-tools.vue"),
  },
  {
    path: "/yourstuff-write",
    name: "yourstuff-write",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/yourstuff/yourstuff-write.vue"),
  },
  {
    path: "/yourstuff/:type?",
    name: "yourstuff",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/yourstuff/yourstuff.vue"),
  },
];

// home分块路由
const homeRouters = [
  {
    path: "/",
    name: "home",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/search/:searchKey?",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/views/_search.vue"),
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
];

// 批改作业
const homeworkRouters = [
  // {
  //   path: "/homework/:storyBookId",
  //   component: Layout,
  //   redirect: "teacher",
  //   children: [
  //     {
  //       path: "teacher",
  //       name: "teacher-marking",
  //       component: () => import("@/views/homework/teacher-marking.vue"),
  //     },
  //   ],
  // },
  {
    path: "/review-homework/:storyBookId/picturebook",
    name: "marking-picturebook",
    component: () => import("@/views/homework/marking-picturebook.vue"),
  },
  {
    path: "/homework/:storyBookId/picturebook",
    name: "homework-picturebook",
    component: () => import("@/views/homework/homework-picturebook.vue"),
  },
];

let routes = [
  ...aboutRouters,
  ...accountRouters,
  ...challengeRouters,
  ...classesRouters,
  ...editorRouters,
  ...memberRouters,
  ...payRouters,
  ...planRouters,
  ...readRouters,
  ...toolsRouters,
  ...writeRouters,
  ...yourstuffRouters,
  ...homeRouters,
  ...homeworkRouters,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  const token = getToken();
  let cookie = getStbCookie();
  if (token || cookie) {
    if (to.name === "signin") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
      return;
    }
    // 存在cookie，说明已处于登录状态
    // 判断用户数据是否处于登录状态，如果不是，则需要获取用户详细信息
    const isLogin = store.getters.isLogin;
    if (!isLogin) {
      await store.dispatch("user/loadUserInfo", { tag: "init" });
    }
  }

  // 路由不存在，跳转到404页面
  if (to.matched.length !== 0) {
    let User = store.getters.User;
    if (!User || JSON.stringify(User) === "{}") {
      User = null;
    }
    let pageName = to.fullPath || "";
    let path = to.name || "";
    if (User && !User.isLogin) {
      // 没有登录，过滤登录重定向url
      if (
        pageName.indexOf("/signin?") != 0 &&
        (pageName.indexOf("yourstuff") >= 0 ||
          pageName.indexOf("account") >= 0 ||
          pageName.indexOf("class-") >= 0 ||
          pageName.indexOf("-editor") > 0 ||
          pageName.indexOf("challenge-info") != -1 ||
          pageName.indexOf("artwork") != -1 ||
          pageName == "complete-info")
      ) {
        let redirect = from.fullPath;
        next({ path: "/signin", query: { redirect: redirect } });
        NProgress.done();
        return;
      }
    } else if (User) {
      // 登录状态

      // 没有激活
      if (!User.isActive) {
        if (
          pageName.indexOf("signin") == -1 &&
          pageName.indexOf("reactivate") == -1 &&
          pageName.indexOf("email-active") == -1
        ) {
          next({ path: "/reactivate" });
          NProgress.done();
          return;
        }
      }

      // 账号安全
      if (
        User.PasswordInsecure &&
        path != "account-change-password" &&
        path != "forgot-password" &&
        path != "reset-password" &&
        path != "forgot-new-password" &&
        path != "reactivate" &&
        path != "complete-info" &&
        to.fullPath != "/account-change-password/insecure"
      ) {
        next({ path: "/account-change-password/insecure" });
        NProgress.done();
        return;
      }

      // 完成信息
      if ((!User.age || User.age < 0) && (User.accountType < 2 || User.accountType > 5)) {
        if (pageName.indexOf("signin") == -1 && pageName.indexOf("complete-info") == -1 && path != "reactivate") {
          if (!User.PasswordInsecure) {
            next({ path: "/complete-info" });
            NProgress.done();
            return;
          }
        }
      }

      // 个人中心
      if (User.isStudent && pageName.indexOf("class-") == 0) {
        next({ path: "/yourstuff" });
        NProgress.done();
        return;
      }

      // 班级学生不能进入我的摘要，重定向到我的写作页面
      if (User.isClassStudent && to.name == "yourstuff") {
        next({ path: "/yourstuff-write" });
        NProgress.done();
        return;
      }

      // 艺术家不能打开我的上传页面，重定向到我的页面
      if (User.isArtist && path == "yourstuff-uploads") {
        next({ path: "/yourstuff" });
        NProgress.done();
        return;
      }
    }
    // 获取未读通知数
    if (User && User.isLogin) {
      store.dispatch("notification/checkNotificationCount");
    }
    next();
  } else {
    next({ path: "/404" });
    NProgress.done();
  }
});
router.afterEach((to) => {
  // 滚动到顶部
  if (to.name !== "home") {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  NProgress.done();
  const obj = {
    path: to.fullPath,
  };
  // 记录路由事件日志
  Vue.prototype.$logEvent.trace(Vue.prototype.$logEvent.EventKey.ROUTE, obj);
});

// 解决跳转到相同路由报错问题
// vue NavigationDuplicated: Avoided redundant navigation to current location:
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
