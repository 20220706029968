const es = {
  common: {
    yes: "Yes",
    no: "No",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    years: " years old",
    more: "Más",
    all: "Todo",
    ok: "OK",
    cancel: "Cancel",
    submit: "Submit",
    skip: "Skip",
  },
  home: {
    first_title: "Become a more effective writer!",
    description_1: "Empowering young writers to create unique stories with amazing illustrations. ",
    description_2: "Storybird’s art inspired writing helps engage students like never before!",
    get_started: {
      title: "Get started",
      step_1: "Step 1",
      step_2: "Step 2",
      step_3: "Step 3",
      item_title_1: "Choose an illustration",
      item_desc_1: "Use our step by step tutorial videos to easily get the most out of your Storybird experience.",
      item_title_2: "Write your story",
      item_desc_2: "Use our step by step tutorial videos to easily get the most out of your Storybird experience.",
      item_title_3: "Publish your book",
      item_desc_3: "Use our step by step tutorial videos to easily get the most out of your Storybird experience.",
    },
    writing_features: "Author’s tools",
    all_illustration: "Illustrations from artists around the globe",
    illustration_number:
      "Our one of a kind, carefully curated art gallery features over 10,000 stunning illustrations from artists and illustrators all over the world.",
    writing_skills: "Boost Writing Skills",
    curriculum:
      "Improve on the basics, learn new techniques, or explore a new style of writing with Storybird’s curriculum that includes over 700 guides, lessons, and challenges.",
    publish_read: "Share your stories",
    submit_stories:
      "Publish your stories to receive expert feedback from educators, professional editors, and other authors.",
    write_now: "Write Now",
    get_tarted: "Get Started",
    teaching_features: "Educator’s Features",
    get_tarted_1: "How-to-guides",
    guide_text_1:
      "Use our step by step tutorial videos to easily and instantly get the most out of your Storybird experience.",
    class_management: "Classroom Management",
    class_management_text_1:
      "Easily manage your class and add assignments using Storybird’s expansive curriculum, or create your own custom assignments.",
    educational_resource: "Additional Resources",
    educational_resource_text_1:
      "Expand your experience with downloadable support images and documents to improve teaching efficiency.",
    bring_storybird: "Bring Storybird to your Classroom",
    what_more: "But wait, there’s more!",
    for_individuals: "For Individuals and Families",
    professional_moderator: "Professional Moderation",
    user_community: "Safe and Friendly Community",
    for_homeschools: "For Homeschoolers",
    writing_function: "Unique writing formats and tools",
    writing_courses: "Language Arts Curriculum",
    siginin: "Iniciar sesión",
    back: "Back",
    lang: "English",
    you: "Tú",
    classes: "Classes",
    home: "Inicio",
    challenge: "Activities",
    read: "Leer",
    write: "Escribir",
    about: "About",
    educators: "Educadores",
    parents: "Padres",
    pricing: "pricing",
    membership: "Afiliación",
    free: "Regístrate para unaprueba GRATUITA",
    challenge_subtitle:
      "Escribe…¡ahora! Elige de cientos lecciones, pruebas y temas para la escritura para todas edades, o haz nuestro Desafío mensual.",
    challenge_ready: "¿Estás listo para un Desafío?",
    challenge_desc:
      "Mejora tus habilidades de escritura con 700+ Desafíos que habían creado por educadores profesionales y autores expertos.",
    writer_title: "Escritores destacados",
    writer_desc:
      "9 millones de escritores en más de 100 países del mundo usan Storybird para contar sus cuentos. Lee las mejores cuentos, publica tu trabajo y recibe comentarios de expertos de maestros, editores profesionales y autores.",
    picture_subtitle: "Cuentos destacados de hoy",
    picture_from: "De",
    picture_submissions: "entregas",
    picture_desc:
      "Elige de más de 1.002 ilustraciones por artistas profesionales para dar vida a tus increíbles historias. Empieza aquí.",
    picture_tody: "",
    picture_book: "Libro ilustrado de hoy",
    comic_subtitle:
      "¡Pájaro y sus amigos están esperándote para escribir sus aventuras! Crea tus propios cómics o lee algunos favoritos de los seguidores.",
    comic: "Cómic de hoy",
    comic_from: "Uno de",
    comic_storybird: "cómics en Storybird!",
    longform_subtitle:
      "Cuando un capítulo no sea suficiente para la historia que quieres contar, muestra al mundo lo que puedes escribir en forma larga. Desafío aceptado.",
    longform_title: "Historia de forma larga de hoy",
    longform_stories: "historias de forma larga en Storybird!",
    flash_subtitle: "Corto y dulce. A veces las historias mejores son aquellas que se puede terminar de una sola vez.",
    flash_here: "Aquí hay algunas ganadoras.",
    flash_today: "",
    flash_fiction: "Microrrelato de hoy",
    flash_fiction_storybird: "microrrelatos en Storybird!",
    poetry_subtitle:
      "¡Exprésate con la poesía! Añade tus propias palabras y las colócalas en una imagen para crear tu propia obra maestra visual.",
    poetry_title: "Poema de hoy",
    poetry_storybird: "poemas en Storybird!",
    join: "Únete a Storybird y empieza a escribir hoy",
    signup: "Regístrate GRATIS",
    yourstuff: "Your Stuff",
    artshop: "Your Art Shop",
    Reading: "Reading",
    Writing: "Writing",
    Badges: "Badges",
    Crowns: "Crowns",
    Tickets: "Tickets",
    Favorites: "Favorites",
    BecomeMember: "Se convierta en miembro",
    AccountInfo: "cuenta",
    FamilyPlan: "Family Plan",
    SchoolPlan: "School Plan",
    SignOut: "Cerrar sesión",
    more: "more",
    referral_plan: "Join our user referral plan to get special discount coupons!",
    account_setting: "Account Setting",
  },
  login: {
    title: "Sign in to Storybird",
    google_login: "Google Sign In…",
    google_login_ad: "Ad blocking software may be blocking Google SignIn",
    google_login_email: "or sign in with email below",
    account: "Username or email address",
    password: "Password",
    forgot: "Forgot your password?",
    signin: "Sign In",
    policy: "Privacy Policy",
    service: "Terms of Service",
    help: "Help",
    need_register: "Sorry! You need to register with Storybird before you can sign in.",
    sign_up: "Sign Up",
    google_signin: "Sign in with Google",
    classcode_signin: "Sign in with CLASS CODE",
  },
  forgot: {
    password: "Forgot password",
    email: "Email",
    format: "Incorrect email format!",
    reset: "We'll send password reset instructions to this address.",
    submit: "Submit",
  },
  signup: {
    safe_title: "How we keep your information safe and secure:",
    safe_item_1:
      "Storybird does not require personal information from children or students in order to create or use an account.",
    safe_item_2: "We encrypt all passwords and stay up-to-date with all modern security methods.",
    choose_date: "Please choose a right date ",
    sorry: "Sorry! ",
  },
  footer: {
    about: "Acerca de",
    service: "Condiciones de Servicio",
    policy: "Política de Privacidad",
    guidelines: "Reglas generales",
    faq: "Preguntas y Respuestas & Centro de ayuda",
    updates: "Información de anuncios",
    contact: "Contacto",
  },
  faq: {
    title: "FAQ",
  },
  vars: {
    ages: {
      all_ages: "Edades",
      preschooler: "Preschooler",
      kid: "Kid",
      tween: "Tween",
      teen: "Teen",
      young_adults: "Young Adults",
    },
    subTypes: {
      scrip_screenplay: "Scrip Screenplay",
      video_games: "Video Games",
      youTube: "YouTube",
      strong_paragraphs: "Strong Paragraphs",
      poem: "poem",
    },
    sortArr: {
      new: "Nuevo",
      new_authors: "Autores nuevos",
      popular_this_week: "Popular esta semana",
      popular_all_time: "Popular todo el tiempo",
      popular_this_month: "Popular este mes",
      popular_this_year: "Popular este año",
    },
  },
  challenge: {
    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
    ThematicCompositions: "Thematic Compositions",
    ChildrenSongs: "Children's Songs",
    LeveledReads: "Leveled Reads",
    HowtoGuides: "How-to Guides",
  },
  read: {
    navs: {
      picture_book: "Libro ilustrado",
      longform_story: "Historia de forma larga",
      comics: "Cómics",
      flash_fiction: "Microrrelato",
      poetry: "Poesía",
      blog: "Blog",
      poetry_write: "Poesía",
      creative_book_write: "Creative Book",
    },
    start_reading: "Empieza a leer",
    search_all_stories: "Search all stories",
    clear: "Clear",
    filter: "Filtrar",
    heart: "Heart",
    use_this_art_to_creat: "Use this art to creat",
    this_art_for_members_only: "This art for members only.",
    cancel: "Cancel",
    upgrade: "Upgrade",
    add_your_comment: "Add your comment",
    add_yours: "Add yours",
    sorry: "Sorry!",
    you_must_be_logged: "You must be logged in to access this. Please log in now or sign up for an account.",
    not_appropriate_for_your:
      "This content is not appropriate for your age level. Explore more stories for your age and start your adventure.",
    only_visible_to_paid_members: "Sorry, this story is only visible to paid members.",
    start_your_7_days: "Start your 7 days free trial or become a member today to unlock this story and more.",
    read_more_books: "Read more books",
    start_now: "Start now",
    start_here: "Start here",
    author: "Author",
    artist: "Artist",
    expert_review: "EXPERT REVIEW",
    categories: "Categories",
    my_Bookshelf: "My Bookshelf",
    add_to_my_Bookshelf: "Add to my Bookshelf",
    remove_from_Bookshelf: "Remove from Bookshelf",
    for_easily_finding: "For easily finding and reading your favorite stories.",
    THE_END: "THE END",
    thanks_for_reading_start: "Thanks for reading",
    thanks_for_reading_end: " ",
    by: "by",
    read_it_again: "Read it again",
    or: "or",
    create_your_own: "Create your own",
    cover: "Cover",
    end: "End",
    book_summary: "Book Summary",
    no_summary: "The author didn't write summary for this book.",
    more: "More",
    less: "Less",
    aLL_COMMENTS: "ALL COMMENTS",
    sort_oldest: "Sort: Oldest",
    sort_newest: "Sort: Newest",
    all_genre: "Todos los géneros",
    no_stories_inconceivable: "No stories? Inconceivable!",
    you_should_write_one: "You should write one. It's sure to get noticed!",
    hearts: "Hearts",
    expressions: "Expressions",
    expert_review_1: "Expert Review",
    comment: "COMMENT",
  },
  write: {
    navs: {
      picture_book: "Picture Book",
      longform_story: "Longform Story",
      comics: "Comics",
      flash_fiction: "Flash Fiction",
      poetry: "Poetry",
      creative_writing: "Creative Writing",
    },
    select_artwork_start: "Selecciona una ilustración para empezar a escribir",
    all: "Todo",
    popular: "Popular",
    recently_added: "Añadido recientemente",
    your_artwork: "Your artwork",
    space_story: "Historia espacial",
    search_all_artwork: "Busca una ilustración",
    my_uploads: "My Uploads",
    images_created_by: "imágenes creadas por",
    artists_from: "artistas de",
    countries: "países",
    animal_story: "Animal Stories",
    family_story: "Family Stories",
    holiday_story: "Holiday Stories",
    spring_story: "Spring Stories",
    friend_story: "Friend Stories",
    food_story: "Food Stories",
  },
  notification: {
    title: "Notificaciones",
    view_more: "Ver todas las notificaciones",
  },
  search: {
    all_type: "All",
    Narrative: "Narrative",
    argument_and_opinion: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    _NA_: "-NA-",
  },
  members: {
    Variety: "Variedad",
    VarietyContent:
      "Con más de 600 cursos y desafíos, Storybird tiene proyectos que se adaptan a las necesidades de cada estudiante. Unas guías sencillas y claras ayudan a los profesores crear planes de estudio únicos.",
    Educational: "Educativo",
    EducationalContent:
      "Todos los cursos y desafíos están alineados con los estándares comunes de enseñanza y vienen con hojas de trabajo y listas de recursos ampliadas.",
    Beautiful: "Hermoso",
    BeautifulContent: "Los estudiantes se inspirarán en el arte único de más de 1.000 artistas mundiales.",
    Expansive: "Expansivo",
    ExpansiveContent:
      "Los estudiantes que se enamoran de la escritura podrán llevar su trabajo en Storybird más allá de la aula y seguir utilizando la plataforma para mejorar sus habilidades de escritura en casa.",
    title:
      "Las herramientas de Storybird inspiran a los jóvenes a desarrollar la confianza y la creatividad en su    escritura. Con 9 millones de miembros y 800.000 aulas participantes, Storybird es el programa de idioma y    literatura más grande del mundo. Encontrarás recursos para educadores debajo.",
    georgia: "¿Por qué elige a Storybird?",
    unlock: "LIBERA TU POTENCIAL CREATIVO.",
    ChooseMembership: "Elige una afiliación para continuar:",
    INDIVIDUAL: "Plan Mensual",
    MONTHLY: "Individual",
    month: "cada mes",
    monthly: "Facturado cada mes",
    price: "Precio original",
    SignIn: "Iniciar sesión",
    BuyNow: "Comprar ahora",
    accounts: "Sólo disponible para las cuentas de educadores.",
    PLAN: "Plan Anual",
    yearly: "yearly",
    membership: "Para la afiliación escolar anual",
    SCHOOLPLAN: "Plan Escolar",
    upTo: "Hasta un",
    OFF: "de descuento",
    Available: "Para",
    members: "100-200 miembros",
    ViewYourPlan: "View your plan",
    DISTRICTPLAN: "Plan Distrito",
    Customizable: "Personalizable",
    districts: "Escuelas y distritos con 200 o más profesores y estudiantes",
    ApplyTo: "",
    teachers: "or more teachers and students.",
    SchoolPlan:
      "El Plan Escolar de Storybird es la opción mejor y más económica para que las escuelas disfruten de los beneficios de la afiliación y hagan una crónica de la escritura de cada estudiante.",
    CustomizedMembership:
      "Don't see what you're looking for? Click here and contact us for a customized membership plan.",
    Resources: "Recursos",
    TeacherNest: "The Teacher's Nest",
    Images: "Imágenes",
    ViewMore: "View more FAQ",
    Info: "Información",
    ABOUT: "ACERCA DE",
    Storybird: "Storybird",
    uniqueLanguage:
      "es una herramienta única de artes del lenguaje centrada en la narración visual. Seleccionamos el arte original de los ilustradores y nuestra biblioteca de más de 10.000 imágenes inspira a los jóvenes escritores a crear libros ilustrados, cómics, historias de forma larga, poesía y microrrelatos. El plan de estudios de escritura de Storybird ofrece más de 600 lecciones, pruebas y temas para la escritura creadas por educadores experimentados y autores expertos, alineadas con los estándares educativos de Common Core.",
    FUNDRAISERS: "RECAUDACIÓN DE FONDOS DE STORYBIRD",
    Fundraisers: "Fundraisers",
    literacy:
      "Los recaudadores de fondos de Storybird promueven la alfabetización y la creatividad sin perder un valioso tiempo en la aula.Los estudiantes pueden escribir libros inspirados en el arte y la poesía basados en su plan de estudios. Los profesores pueden activar una tienda privada online para los padres con sólo un clic. Los estudiantes pueden crear libros hermosos, los padres pueden recibir recuerdos duraderos y los profesores pueden recaudar los fondos que son necesarios. Próximamente habrá información más detallada.",
    Links: "Links",
    StorybirdHW: "Storybird",
    Literacy: "Storybird and Literacy",
    ContactUs: "Contact us",
    Educators: "Storybird Studio for Educators",
    Channel: "Storybird Youtube Channel",
    Testimonials: "Testimonios",
    RecentTweets: "Recent Tweets",
    right: "Yes! Storybird gets it right.",
    ISTE: "ISTE Teacher of the Year",
    Changed: "Changed some of my learners love of writing from 0 to 100.",
    Wellington: "Year 5/6 teacher, Wellington NZ",
    MoreInformation: "More information",
    visit: "or come visit us on our",
    Blog: "Blog",
    orOn: "or on",
    Facebook: "Facebook",
    and: "and",
    Twitter: "Twitter",
    Copyright: "Copyright Storybird Inc.",
    HowItWorks: "Cómo sirve",
    individualAccount: "para una cuenta individual.",
    Upgrade: "Actualice",
    familyPlan: "un plan para las familias,disponible para 5 miembros.",
    Invite: "Invite",
    familyMembers: "a los miembros de su familia a unirse y comenzar juntos su viaje literario.",
    WhyStorybird: "¿Por qué elige a Storybird?",
    libraryMemories: "Una biblioteca de memorias.",
    Safe: "Seguro",
    WorldClass: "Los escritores jóvenes pueden encontrar inspiración en el arte de más de 1.000 artistas.",
    Encouraging: "Alentador",
    OurTeam: "Nuestro equipo de expertos ofrece comentarios productivos sobre todo su hijo escribe en Storybird.",
    YoungWriters: "Los escritores jóvenes pueden encontrar inspiración en el arte de más de 1.000 artistas.",
    AllCourses:
      "Todos los cursos son enseñados por profesores y autores profesionales y siguen los estándares educativos comunes.",
    COURSES: "CURSOS Y DESAFÍOS ADECUADOS PARA EL APRENDIZAJE EN FAMILIA.",
    UNLOCKYOUR: "LIBERA TU POTENCIAL CREATIVO.",
    ChooseMembershipContinue: "Elige una afiliación para continuar:",
    INDIVIDUAL2: "Plan Mensual",
    monthly2: "Facturado cada mes",
    MONTHLY2: "Individual",
    NotAvailable: "No es disponible para las cuentas de educadores.",
    FAMILYPLAN: "Plan para las familias",
    familyMembership: "Para la afiliación anual de las familias",
    familyMembership_1: "Annual family plan includes up to",
    familyMembership_2: "six family members",
    members2: "5 miembros de la familia",
    ViewYourPlan2: "View your plan",
    FamilyPlanMsg:
      "El Plan Familiar de Storybird es la opción mejor y más económica para que las familias disfruten de los beneficios de la afiliación y hagan una crónica de la escritura de cada miembro de la familia.",
    oneOfKind:
      "UNA COMUNIDAD DE APRENDIZAJE ÚNICA EN SU GÉNERO, MODERNA, SEGURA, QUE OFRECE INSTRUCCIÓN PROFESIONAL EN MÚLTIPLES PLATAFORMAS DE HARDWARE.",
    FAQ: "View more FAQ",
    shop: {
      explorerBlocked:
        ' Sorry, your explorer blocked your payment, please click "Continue Payment" to complete the purchase.',
      ContinuePayment: "Continue Payment",
      agree: "I agree",
      Cancel: "Cancel",
      Thank: "Thank you for your payment!",
      PayFail: "Pay Fail",
      paymentMethod: "Please select payment method",
      Check: "Check",
    },
    Unlock1: "LIBERA TU",
    creative: "POTENCIAL CREATIVO.",
    unlock_potential: "LIBERA TU POTENCIAL CREATIVO",
    SchoolPlan1: "Storybird Summer Spectacular! ",
    JumpStart: "Beat boredom with our summer time plan!",

    NowOnly: "For a limited time only, get three months of Storybird for only $20!",
    dollar: "$21",
    perUser: "per user!",
    TEACHERONLY: "TEACHER ONLY",
    MONTHLYPLAN: "Plan Mensual",
    billedMonthly: "Facturado cada mes",
    perMonth: "cada mes",
    notInclude: "Does not include Student accounts",
    annualfee: "Cuota de $29.99 sólo una vez",
    members1: "Para 2-5 miembros de la familia",
    LearnMore: "Learn more",
    ViewYourPlan1: "View your plan",
    CLASSROOMAND: "Plan Escolar",
    SCHOOLPLAN1: "SCHOOL PLAN",
    memberships: "Para 100-200 miembros",
    teachersStudents: "teachers and students",
    PriceBased: "Price based on number of users",
    ViewPricing: "View Pricing",
    schoolsDistricts: "Escuelas y distritos con 200 o más profesores y estudiantes",
    membership1: "Monthly plan membership renews automatically, and can be cancelled at any time.",
    purchase: "Save 50% when you purchase an annual membership for $29.99",
    Whyupgrade: "¿Por qué actualizar?",
    AccessHundreds: "Accede a cientos de lecciones y temas de escritura,",
    yourWork: "publica tu trabajo y recibe comentarios de expertos para ayudarte a mejorar la escritura.",
    membersSay: "El 85% de los miembros dicen que Storybird les ha hecho más creativos",
    moreCreative: "more creative",
    membersSay1: "El 87% de los miembros dicen que Storybird les ha dado más confianza en la escuela",
    moreSchool: "more confidence at school",
    StorybirdGiven: "Storybird has given them",
    sayStorybird:
      "El 75% dice que Storybird ha mejorado sus notas y les ha ayudado a dar forma y comunicar mejor sus ideas",
    improvedGrades: "improved their grades",
    theirIdeas: "and helped them better shape and communicate their ideas",
    NonMember: "Non-Member",
    FreeTrial: "Free Trial",
    Member: "Member",
    BENEFIT: "BENEFIT",
    SCHOOLPLAN2: "School Plan",
    TeacherPlan: "Teacher Plan",
    Members: "Miembros",
    million: "9 millones de miembros. Utilizado por más de 500.000 educadores.",
    notAvailabe: "You're in School Plan, Monthly Plan is not availabe.",
    notAvailabe1:
      "You‘re in School Plan. Yearly Plan is currently not available and can be made when your School Plan expires.",
    MonthlyMembership: "Storybird Monthly Membership",
    accounts1:
      "Please note that the teacher only plan does not include any student accounts. You will be able to invite 40 students and teachers to your class for a free trial, but the invitees membership will not be included In this plan.",
    StorybirdMembership: "Storybird Annual Membership",
    membership2: "Your membership will be changed from monthly to annually.",
    ChooseMembership1: "Elige una afiliación para continuar:",
  },
  district: {
    Select: "Select...",
    Kindergarten: "Kindergarten/Preschool",
    Elementary: "Elementary School",
    MiddleSchool: "Middle School",
    HighSchool: "High School",
  },
  pay: {
    confirmation: "Waiting for the order confirmation",
    complete: "Your payment is complete.",
    failed: "Your payment failed this time.",
    Status: "Status",
    Waiting: "Waiting for payment",
    Reviewing: "Reviewing",
    Returned: "Returned",
    ReturnReason: "Return reason",
    Order: "Order",
    Placed: "Placed",
    Total: "Total",
    Item: "Item",
    Detail: "Detail",
    Pay: "Pay",
    ConfirmOrder: "Confirm Order",
    OrderCompleted: "Order completed",
  },
  accountNav: {
    General: "General",
    notifications: "Email notifications",
    Orders: "Orders",
  },
  account: {
    Account: "Account",
    Username: "Username:",
    Email: "Email:",
    AccountType: "Account type",
    Regular: "Regular",
    Teacher: "Teacher",
    Artist: "Artist",
    Member: "Member",
    Joined: "Joined on",
    DeleteAccount: "Delete Account",
    Membership: "Membership",
    FreeTrialEnds: "Free trial ends",
    CancelPlan: "Cancel Plan",
    Theme: "Theme",
    Change: "Change",
    Password: "Password",
    ChangePassword: "Change Password",
    LinkGoogle: "Link Google",
    LinkingYour: "Linking your account will allow you to login using Google.",
    Avatar: "Avatar",
    Country: "Country",
    UpdateAvatar: "Update Avatar",
    SaveChanges: "Save Changes",
    Saved: "Saved",
    RemoveSucessfully: "Remove sucessfully.",
    RenewOn: "Renew on",
    ExpiresOn: "Expires on",
    update_profile: "Update your profile:",
    birth: "Date of Birth",
    choose_birthday: "Choose birthday",
    birth_information:
      "We will never publish or share your birth date. We only use this information to show you age-appropriate stories and lessons on Storybird.",
    guardian: "Parent/Guardian",
    email: "Email",
    incorrect_email: "Incorrect email format!",
    country: "Country",
    save: "Save",
    account_not_activated: "Your account has not been activated",
    check_email: "Please check your email to activate your account",
    resend_email: "Resend Email",
    or: "or",
    logout: "Logout",
    account_actived: "I have activated my account",
    referral_code: "Referral code",
    invalid_referral_code: "Invalid referral code",
    referral_code_not_allow_yourself: "You cannot use your own referral code",
  },
  yourstuff: {
    Badges: "insignias",
    start_writing: "¡Empieza a escribir hoy!",
    your_chanllenge: "Tus insignias de Desafíos",
    hello: "Oh, hello",
    donot_have: "You don't have any writing activity yet.",
    first_writing: "Start your first writing challenge",
    your_school_plan: "Your School Plan",
    recent_writing: "Escritura reciente",
    your_story: "Your story is saved as draft.Keep writing!",
    submit_review: "Submit for review",
    cheers: "Cheers! You finished your story, now submit to the experts to get review.",
    under_review: "Under review",
    reviewing: "Experts are reviewing your work, please be patient!",
    edit: "please edit",
    continue_writing: "Continue writing",
    not_meet_guidelines: "Sorry, your story does not meet our guidelines.",
    edit_1: "EDIT",
    read: "READ",
    go_to_course: "Go to the course",
    nothing_in_progress: "Nothing in progress. Start writing a story！",
    progress: "Progreso",
    lesson: "lección",
    quiz: "Prueba",
    writing: "Escritura",
    recent_reads: "Lecturas recientes",
    nothing_in_progress_reading: "Nothing in progress. Start reading a story！",
    start_reading: "Start reading today",
    continue_to: "Continua a",
    yourstuff: "Tus cosas",
    classes: "Clases",
    art_shop: "Art Shop",
    tools: "Herramientas",
    getting_started: "Para empezar",
    dashboard: "Escritorio",
    tabs_reading: "Leer",
    tabs_badges: "Insignias",
    crowns: "Coronas",
    tickets: "Entradas",
    favorites: "Favoritos",
    table_tickets: "Entradas de reseña",
    redeemed_successfully: "Redeemed successfully",
    exceeded_limit: "Sorry, you have exceeded the limit of redeem tickets this month.",
    no_package: "No this kind of package",
    please_login: "Please login first.",
    earn_crowns: "Ganar Coronas",
    daily_writing: "Escritura diaria",
    complete_challenge: "Terminar Desafío",
    earn_desc:
      "Gana 20 Coronas en historias entregadas para reseña en sábado y domingo cuando son aceptados para la biblioteca pública. (Límite, una historia cada día)",
    spend_crowns: "Gastar Coronas",
    redeem_for: "Canjear por entradas de reseña.",
    _70_crowns: "70 Coronas",
    _70_crowns_for_1: "Canjea 70 Coronas por 1 entrada de reseña. ",
    limit_redemptions: "Límite de tres reembolsos cada mes",
    _200_crowns: "200 Coronas",
    _200_crowns_pdf: "Usa 200 Coronas para canjear por 1 descarga de PDF. ",
    redeem_here: "Canjea aquí",
    redeem_for_pdf: "Canjear por descargas de PDF.",
    crowns_earned: "Coronas ganadas",
    keep_writing: "¡Sigue escribiendo, publicando y terminando Desafíos para ganar más Coronas en Storybird!",
    earn_5_day: "¡Gana 5 Coronas por cada día que regreses y escribas! Solo se puede ganar una vez al día.",
    earn_10_crowns: "Gana 10 Coronas cuando terminas una lección o prueba con éxito.",
    book_approved: "Libro aceptado para publicación pública",
    book_publick_5:
      "Gana 10 Coronas cuando tu libro ha sido revisado y aceptado para la biblioteca pública. El máximo es 5 obras cada mes.",
    double_crown_weekends: "¡Fines de semanas de doble coronas!",
    daily_writing_1: "Escritura diaria",
    complete_challenge_1: "Terminar Desafío",
    published: "Publicado a la biblioteca pública",
    ticket: {
      your_tickets: "Tus entradas",
      buy_more: "Compra más entradas de reseña",
      review: "Entradas de Reseña",
      recent_ticket: "Actividad reciente de entradas",
      get_more: "Recibir más entradas",
      membership: "afiliación de Storybird",
      as_of_membership:
        "Como parte de tu afiliación pagada, tú cuenta se repone automáticamente a 10 entradas cada mes",
      start_month_10: "Empieza cada mes con 10 entradas",
      featured_writer: "Escritor destacado",
      as_a_reward:
        "Como un premio para trabajo excepcional, recibirás una entrada de reseña en cualquier momento unas de tús historias están destacadas en la página principal de Storybird.",
      _1_ticket: "1 Ticket",
      redeem_crowns: "Canjear coronas",
      redeem_crowns_ticket: "Puedes canjear coronas por entradas de reseña.",
      _70_crowns_ticket: "70 coronas cada entrada",
      redeem_here: "Canjear aquí",
      purchased: "",
      click_here: "Entradas de reseña adicional se pueden comprar aquí",
    },
    more: "More",
    read_obj: {
      recent: "Recent",
      nothing: "Nothing in progress. Start reading a story！",
      reading: "Start reading today",
    },
    pdfs: {
      created: "¡Todavía no has creado una PDF!",
      create_one: "¡Crea una ahora!",
      flash_fiction: "Microrrelato",
      picture_book: "Libro ilustrado",
      longform_story: "Historia de forma larga",
      poetry: "Poesía",
    },
    badges: {
      challenges_accepted: "Challenges accepted",
      continue_writing: "Continue writing",
      challenges_completed: "Challenges Completed",
      go_to_course: "Go to the course",
      read: "Read",
      edit: "Edit",
      no_completed_challenges: "¡Todavía no has completado un Desafío!",
    },
    favorites_obj: {
      bookshelf: "Estantería",
      hearted: "Con corazones",
      picture_book: "Libro ilustrado",
      longform_story: "Historia de forma larga",
      comic: "Cómic",
      flash_fiction: "Microrrelato",
      poetry: "Poesía",
      creative_writing: "Escritura creativa",
      favourite_illustrations: "Favorite Illustrations",
      details: "Details",
      empty_illustration:
        "You don't have any Favorite Illustrations yet. \n You can give likes to Illustrations and use them to create new books.",
      empty_bookshelf: "You don't have any books in your Bookshelf.",
      empty_hearted: "You don't have any Favorite Books yet.\n You can give likes to Books and read them at any time.",
    },
    how_to_guides: "Guías de Storybird",
    _class: {
      withdraw: "Withdraw",
      edit: "Edit",
      join_class: "Unirte a la clase",
      accept_join: "Accept and join",
      not_join: "I don't want to join",
      leave: "Leave the class",
      enter_code: "Rellena con la contraseña de clase aquí",
      assignments: "Assignments",
      submitted: "Stories Submitted",
      featrued_books: "Class Books",
      member: "Class Member",
      student: "STUDENT",
      teacher: "TEACHER",
      enter_desc: "Rellena con la Contraseña de Clase que tu maestr@ te proveyó",
      enter_desc_1: "Rellena con la Contraseña de Clase que tu maestr@ te proveyó",
      confirm_leave: "Confirm and leave the class",
      class_ended: "Sorry, this class has already ended. Please contact your teacher for more information.",
      become_a_member: ", become a member today to join the class and start your journey with Storybird.",
      become_a_member_1: "Become a member",
      welcome_class: "Welcome to our class",
      get_start: "Let’s get started!",
      start_writing: "Start writing",
      accept_join_1: "Accept and join...",
      sure_to_join: "Are you sure you don't want to join the class?",
      donot_join: "I don't want to join...",
      donot_join_1: "I don't want to join",
      refused_join: "You have refused to join the class.",
      incorrect_format: "Incorrect class passcode format",
      join_class_1: "Join the class...",
      join_successfully: "Joined successfully!",
      already_joined: "You've already joined this class. To access the class, please go to your class list. ",
      not_membership:
        "Whoops, this class is not covered with Storybird School Plan membership. Individual Membership is needed to join this class.",
      sure_to_leave: "Are you sure you want to leave this class?",
      leave_1: "Leaving...",
    },
    pop_account: {
      dashboard: "escritorio",
      account: "Cuenta",
      avatar: "Actualizar avatar",
      signout: "Cerrar sesión",
    },
    repassword: "Passwords do not match",
    delete: {
      desc: 'Deleting your account is permanent. Your stories, connections, and profile will be lost. There is no "undo."',
      continue: "I understand, continue",
      cancel: "Cancel",
      password: "Please enter your password",
      confirm_del: "Delete Account",
    },
    avatar: {
      choose: "Choose an avatar",
      represent: "This avatar will represent you on Storybird.",
      save: "Use this Avatar",
      cancel: "Cancel",
    },
    pdf: {
      pages: "{page_num} page(s) PDF",
      re_generate: "Re-generate",
    },
    writing_obj: {
      drafts: "Drafts",
      published: "Published",
      featured: "Featured",
      nothing: "Nothing in progress. Start writing a story！",
      start: "Start writing today",
      publish: "Publish",
      edit: "Edit",
      make_pdf: "Make PDF",
      print: "Print",
      print_1: "Imprimir",
      to_longform: "Convert to Longform Story",
      to_draft: "Move to Draft",
      to_private: "Make Private",
      remove: "Remove from Class",
      delete: "Delete",
      remove_1: "Remove",
    },
    publish: {
      not_finished: "¡No has terminado del todo!",
      add_detail: "Añadir o revisar los detalles de la historia",
      del_story: "Borrar historia",
      save_draft: "Guardar el borrador.",
      come_back: "Puedes regresar y trabajar en ello más tarde.",
      finished: "Estoy terminado. Publicar mi historia",
      publicly: "En público",
      review_ticket: "Usar entrada(s) de reseña para entregar a la biblioteca pública.",
      learn_more: "Aprender más.",
      select_chapter: "Please select the chapter(s) you would like to submit for review:",
      privately: "En privado",
      visible: "Tu historia sólo será visible para ti.",
      submit_teacher: "Entregar a mi(s) maestro(s) en mi clase.",
      submit_teacher_1: "Entregar al maestro en mi clase:",
      visible_teacher: "Tu historia sólo será visible para ti y tu(s) maestro(s).",
      paid_members: "Sumisiones para los maestros están disponibles por pruebas gratuitas y miembros.",
      change_apply: "Changes apply to all chapters in the book",
      language: "Idioma",
      required: "OBLIGADA",
      select_language: "Selecciona la idioma de tu historia.",
      summary: "Resumen",
      optional: "OPCIONAL",
      appears: "Esto aparece con tu historia.",
      tags: "Etiquetas",
      separate: "Separate tags with a space.",
      tags_help: "Usa las etiquetas para organizar tus historias",
      Categories: "Categorías",
      choose_2: "elige 2",
      submit_review: "Entregar para reseña ",
      publish: "Publish",
      dashboard: "Ir al escritorio",
      back_edt: "O, regresar y editar",
      policy: "Política de privacidad",
      service: "Condiciones de servicio",
      help: "Ayuda",
      detail: "Details",
      book_detail: "Book Details",
      no_package: "No this kind of package",
      publishing_member: "Publishing in this way is only available for free trial users and paid members.",
      submitted_teacher: "Your story <em>{bookTitle}</em> was submitted to your teacher.",
      published_teacher: "Your story <em>{bookTitle} was published {publishType}.",
      submitted_review: "Your story <em>{bookTitle}</em> was submitted successfully for review.",
      published_privately: "Your story <em>{bookTitle}</em> was successfully published privately.",
      pop_del: {
        del_story: "Delete this story",
        msg: "Are you sure you want to delete this story? This is permanent and non-reversible.",
        cancel: "Cancel",
        delete: "Delete",
        have_deleted: "You have deleted <em>{result}</em>. It has been removed from the system.",
      },
    },
    print: {
      printable_books: "Libros imprimibles",
      cart: "Carrito",
      order: "Imprimir pedido",
    },
    profile_write_book: "Write Your Book",
    profile_print_book: "Print Your Book",
  },
  Classes: {
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
    CLASSPASSCODE: "CLASS PASSCODE",
    Show: "Show",
    Hide: "Hide",
    Showcase: "Showcase",
    Assignments: "Assignments",
    Submissions: "Submissions",
    Roster: "Roster",
    settings: "Class settings",
    your_classes: "Your Classes",
    compilation_works: "Compilation Works",
    compilation_comics: "Comics Compilation",
    compilation_poetry: "Poetry Compilation",
    compilation_creator: "Compilation Creator",
    compilation_create: "Create",
    compilation_your_compilation: "Your Compilation",
    compilation_add_title: "Add book's title here",
    compilation_preview: "Preview",
    compilation_comics_lastest: "You can assemble at least 5 Comics into a printable Compilation book.",
    compilation_poetry_lastest: "You can assemble at least 5 Poetry into a printable Compilation book.",
    back: "Back",
    finish: "Finish",
    no_compilation: "There is no Book Compilation now",
    no_compilation_books: "Books from Submission will show here",
  },
  classAssignment: {
    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
    ThematicCompositions: "Thematic Compositions",
    Children: "Children's Songs",
    LeveledReads: "Leveled Reads",
    Click: "Click the challenge to select. Click one more time to cancel.",
    set: "Please set a due date",
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
    Sorry:
      "Sorry, this challenge is only visible for paid members. Upgrade to be a member of Storybird today to unlock all the challenges.",
    addAssignmentPlaceholder: "Add artwork url(E.g:https://www.storybird.com/comic-artwork?art_id=281)",
  },
  classSubmission: {
    We: "We can’t wait to see what your students write!",
  },
  classRoster: {
    Role: "Role",
    Email: "Email",
    Username: "Username",
    Name: "Name",
    InitPassword: "InitPassword",
    Status: "Status",
    PRIMARY: "PRIMARY",
    TEACHER: "TEACHER",
    STUDENT: "STUDENT",
    Joined: "Joined",
    Waiting: "Waiting for user",
    Add: "Add Student",
    Choose: "Choose a course:",
    AddTeacher: "Add Teacher",
    Import: "Import",
    Reset: "Reset Password",
    RemoveInvitation: "Remove Invitation",
    Leaveclass: "Leave class",
    RemoveFromClass: "Remove from class",
    Sorry: "Sorry, your membership has expired. Please renew to resume the class.",
    Areyou: "Are you sure you wish to remove this user from your class?",
    Removered: "Remove",
    Remove: "Remove this invitation?",
    Quit: "Quit",
    sure: "Are you sure you want to leave this class?",
    Cancel: "Cancel",
    ResetPassword: "Reset password for this student?",
    permission: "No permission",
    Tips: "Students join School Plan automatically when they join this class, Teachers need to be invited to join School Plan by Administrator.",
    SigninLink: {
      desction_1: "Your students can login by QR code, login link or class code,",
      desction_2: "and The default password is",
      displayQrcode: "Display QR code",
      scan_to_login: "Students scan to sign in",
      copyLoginLink: "Copy login link",
      clickToLogin: "Share the link to students",
      loginClassCodeTitle: "Class code",
      loginByClasscode: "Student sign in with class code",
      download: "download",
      Copied: "Copied",
      LoginTitle: "Welcome, {className} Class!",
      LoginTip: "Click your user name to login.",
      LoginPassword: "Please input your password",
    },
    AddStudent: {
      createTitle: "Create your student accounts",
      description_1: "If your students don’t have accounts you can generate accounts for them here.",
      description_2: "They can sign in by scanning a QR code or clicking on a class link.",
      description_3: "You can also add students via their",
      description_3_2: "You can add more students later on the roster.",
      or: "or",
      email: "email",
      classCode: "class code",
      inputPlaceHolder: "Type student's name and email, separated by commas, on a new line for each student",
      generateAccounts: "Generate accounts",
      inputEmpty: "Please input student's name and email",
      generateSuccess: "Generte success",
      buy: "Buy School Plan",
      renew: "Renew Plan",
      planExpired: "Your school plan has expired. Please renew to resume the class.",
      requirePlan: "In order to add students, you must be part of a school plan.",
      account_value_err: 'Accounts cannot contain ", "',
      inputError: "The information entered in line {row} is abnormal.",
      add_student_username_error: "The name entered in line {row} can contain only numbers and letters.",
      add_student_email_error: "The email entered in line {row} is abnormal.",
    },
    ClassCode: {
      title: "Your students sign in with CLASS CODE",
      desc_1: "They can sign in by entering the Class Passcode",
      desc_2: "through",
      desc_3: "“Sign in>Sign in with CLASS CODE>Sign in through password”",
    },
    CheckMemberError_1: "Sorry, your membership has expired. Please renew to resume the class.",
    CheckMemberError_2: "Sorry, your free trial has expired. Become a member today to unlock more fun with Storybird.",
    CheckMemberError_plan_none: "Become a school plan member today to unlock more fun with Storybird.",
    CheckMemberError_plan_expired: "Sorry, your school plan has expired. Please renew to resume the class.",
    ResetPasswordTitle: "Reset {name}'s password to {password}",
    inputPassword: "Please input password",
    passwordError: "The password must consist of four digits.",
    ResetPasswordSuccess: "Reset password success",
    ResetClassCode:
      "Are you sure to generate a new Class Code. After generating a new one, the QR code and Login link will also be regenerated.",
  },
  ClassList: {
    CreateAClass: "Create a class",
    JoinAClass: "Join a class",
    CreateClass: "Create Classes",
    CreateDialog: {
      Description_1: "Start by creating a class. A class enables you",
      Description_2: "to onboard students and review work.",
      formTitle: "Enter your class details",
      className: "Class name",
      classGrade: "Class grade level",
      teacherName: "Primary teacher's name",
      classEndDate: "Class end date",
      CreateClass: "Create class",
      Step_1_title: "STEP 1",
      Step_1_desc: "Create your class",
      Step_2_title: "STEP 2",
      Step_2_desc_1: "Create your",
      Step_2_desc_2: "student accounts",
      Step_3_title: "STEP 3",
      Step_3_desc: "Enjoy your class",
      ClassComplete: "Your class is complete",
      CompleteDesc_1: "Now share the login link to your students.",
      CompleteDesc_2: "The default password is",
      CompleteDesc_3: ", it can be changed after login.",
      CompleteButtonText: "Complete",
    },
  },
  classSettings: {
    ClassSettings: "Class Settings",
    ClassName: "Class name",
    Classgradelevel: "Class grade level",
    ReadOtherBooks: "Read other's books",
    AllownPublicPublish: "Publish their books online",
    Choose: "Choose an end date for this class:",
    Youcan: "You can change this date at any time.",
    UpdateClass: "Update Class",
    Select: "Select...",
    Kindergarten: "Kindergarten/Preschool",
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
    5: "5th",
    6: "6th",
    7: "7th",
    8: "8th",
    9: "9th",
    10: "10th",
    11: "11th",
    12: "12th",
    College: "College",
    Occupational: "Occupational",
    Other: "Other",
  },
  footerRight: {
    PrivacyPolicy: "Privacy Policy",
    Terms: "Terms of Service",
    Help: "Help",
  },
  PopClassShare: {
    ShareSettings: "Share Link",
    ShareYou: "Share your class showcase with friends and family.",
    protects: "Storybird protects your class students' privacy, please set a password for this page visiting.",
    Updated: "Updated",
    ShareLink: "Share link",
    CopyUrl: "Copy Link",
    Copied: "Copied",
  },
  classCreate: {
    CreateClass: "Create a class",
    Start: "Start by creating a class. A class enables you to onboard students and review work.",
    ClassName: "Class name",
    ClassGrade: "Class grade level",
    Primary: "Primary teacher's name",
    Choose: "Choose an end date for this class:",
    You: "You can change this date at any time.",
    Create: "Create Class",
    Cancel: "Cancel",
  },
  joinClass: {
    Enter: "Enter the Class Passcode",
    ENTERTHECLASSPASSCODEHERE: "ENTER THE CLASS PASSCODE HERE",
    Join: "Join the class",
    Incorrect: "Incorrect class passcode format",
    Jointheclass: "Join the class...",
    JoinedSuccessfully: "Joined successfully!",
    HavBeenUsedUp: "All the accounts have been used up, please ask your teacher for help.",
  },
  planHead: {
    Sorry: 'Sorry, your explorer blocked your payment, please click "Continue Payment" to complete the purchase.',
    ContinuePayment: "继续支付",
    PayFail: "Pay Fail",
    Thank: "Thank you for your payment!",
  },
  challenge_guides: {
    AllCategories: "All categories",
    Plot: "Plot",
    Structure: "Structure",
    Character: "Character",
    Dialogue: "Dialogue",
    Setting: "Setting",
    Genre: "Genre",
    Craft: "Craft",
    Vocabulary: "Vocabulary",
    Grammar: "Grammar",
    Reference: "Reference",
    Search: "Search",
    all_format: "All Formats",
    Comic: "Comic",
    FlashFiction: "Flash Fiction",
    PictureBook: "Picture Book",
    LongformChapter: "Longform Chapter",
    Poetry: "Poetry",
    AllGenres: "All Genres",
    Life: "Life",
    Humor: "Humor",
    Magic: "Magic",
    Family: "Family",
    Animals: "Animals",
    Friends: "Friends",
    Mystery: "Mystery",
    Romance: "Romance",
    Thriller: "Thriller",
    Adventure: "Adventure",
    SchoolLife: "School Life",
    Fantasy: "Sci-fi/Fantasy",
    AllTopic: "All Topic",
    Pet: "Pet",
    Travel: "Travel",
    Food: "Food",
    Guides: "Guías",
    Build: "Aumenta tus habilidades con consejos y instrucciones para mejorar tu escritura.",
    Format: "formato",
  },
  challenge_tag: {
    BasicWriting: "Escritura básica",
    CreativeWriting: "Escritura creativa",
    ThematicCompositions: "Composiciones temáticas",
    Children: "Canciones para niños",
    LeveledReads: "selecciones por edad",
    Guides: "Guías",
  },
  challenge_info: {
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
  },
  challengeHome: {
    UNITS: "unidades",
    CHALLENGES: "Desafíos",
    PARTICIPATED: "participantes",
    Units: "Las unidades",
    Recently: "Añadido recientemente",
    Featured: "Destacado",
    Filter: "Filtrar",
    Clear: "Clear",
    WritingType: "Tipo de escritura",
    All: "Todo",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
    AllCategories: "Todas",
    BasicChallenges: "Desafíos básicos",
    BasicChallengesDetails:
      "Te convierte en un mejor escritor con temas para la escritura, lecciones, videos tutoriales y pruebas. Gana las insignias y mejora tu confianza en la página y más allá.",
    CreativeChallenges: "Desafíos creativos",
    CreativeChallengesDetails:
      "Explora tu creatividad con consejos expertos en escritura de exitosos autores y profesionales de la industria. Aprende a escribir las canciones, las novelas gráficas, los guiones, la fantasía y más.",
    ThematicCompositions: "Composiciones temáticas",
    ThematicCompositionsDetails:
      "Disfrute las oportunidades del escritura creativa con hilos temáticos y temas estacionales.",
    ChildrensSongs: "Canciones para niños",
    ChildrensSongsDetails: "Canta tan dulce como un pájaro a las canciones de palabras y asombros cotidianos.",
    LeveledReads: "selecciones por edad",
    LeveledReadsDetails: "Aumenta tus habilidades de escritura leyendo historias destacadas por autores de Storybird.",
  },
  challengeUnit: {
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",

    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
  },
  tools: {
    All: "All",
    PictureBook: "Picture Book",
    LongformStory: "Longform Story",
  },
  printTtabs: {
    PrintableBooks: "Libros imprimibles",
    Cart: "Carrito",
    PrintOrder: "Imprimir pedido",
  },
  preOrder: {
    Alipay: "Alipay",
    Wechat: "Wechat：",
    FontImage: "Font image",
    Upload: "Upload",
    BackImage: "Back image",
  },
  cart: {
    View: "View print cart",
    Youhaven: "Todavía no has escrito ningunos libros ilustrados o historias de forma largo. ",
    startwritingtoday: "¡Empieza a escribir hoy",
  },
  custome_challenge_cover: {
    Writing: "Writing",
    NA: "-NA-",
    View: "View assignment requirements",
  },
  pop_assign_edit: {
    Delete: "Delete",
    Edit: "Edit",
  },
  pop_print_book_type: {
    All: "All",
    PictureBook: "Picture Book",
    LongformStory: "Longform Story",
  },
  pricing: {
    title: "Unlock your creative potential",
    desc_1: "is used by millions of students, teachers, and",
    desc_2: "casual writers. Pick the plan that best meets your needs.",
    compare: {
      free: "Free",
      basic_plan: "Basic Plan",
      tips_free: "Reading & Community Access",
      tips_individual: "Subscription billed monthly (cancel anytime)",
      tips_individual_yearly: "Subscription billed annually (cancel anytime)",
      tips_school: "Starting at 10 students, billed annually",
      free_7_days: "Free for 7 days",
      get_start: "Get Started",
      month_user: "/month",
      year_user: "/year",
      individual_plan: "Individual Plan",
      buy_now: "Buy Now",
      monthly: "Monthly",
      yearly: "Yearly",
      school_plan: "School Plan",
      school_plan_price_desc: "Special Discount",
      best_deal: "Best Deal",
      core_features: "Core features",
      access_book_you_created: "Access to edit books you've created",
      need_buy_tickets: "Moderation tickets available for purchase",
      class_features: "Class features",
      advanced_features: "Advanced features",
      with_artwork: "Write with unlimited artwork",
      publish_books: "Publish books publicly",
      read_books: "Read books in the library",
      access_guides: "Access “how-to” guides",
      access_resources: "Access courses and resources",
      challenges_to_class: "Assign challenges to class",
      review_submissions: "Review student submissions",
      manage_membership: "Admin dashboard - manage membership licenses",
      tract_performance: "Admin dashboard - Track student performance",
      create_custom_assignments: "Create custom Assignments",
      edit_and_provide_feedback_on_students_work: "Edit and Provide Feedback on students' work",
      interact_with_community: "Interact with Community",
      access_custom: "Access custom stickers, covers, and profiles",
      featured_homepage: "Chances to be featured on homepage",
      review_tickets: "Review tickets and PDF download",
      buy_family_plan: "Discounted Family Memberships Available here!",
      school_plan_free_trial: "Contact us for free trial >>",
    },
    voice_title: "User Testimonials",
    voice: {
      erin_klein: {
        name: "Erin Klein",
        email: "Blogger: Kleinspiration.com",
        text: "“I think I love Storybird just as much as my students enjoy using it. There are no ads on the site, it’s super user-friendly, and Storybird is very engaging for teachers and students.”",
      },
      larry_ferlazzo: {
        name: "Larry Ferlazzo",
        email: "Teacher, author, blogger",
        text: "“Users can choose artwork from a specific artist and then add text to create a storybook... I’m adding the site to The Best Places Where Students Can Write Online.”",
      },
      shannon_miller: {
        name: "  Shannon Miller  ",
        about: "Teacher, librarian, education technology consultant",
        text: "“Storybird can be used by all ages, for all different subjects... Students can rewrite a classic... explain a science concept or retell a historical event.”",
      },
      amy_jussel: {
        name: "Amy Jussel",
        about: "Founder, Executive Director of Shaping Youth",
        text: "“I can’t think of a better way to encourage literary exploration, artsy fun and new media skills to inspire a whole new flock of creative storytellers”",
      },
      drew_buddie: {
        name: "Drew Buddie",
        about: "Learning technologist",
        text: "“Storybird rocks. One of the most engaging apps I’ve used with students in 20 years.”",
      },
      john_mikulski: {
        name: "John Mikulski",
        about: "Middle School Assistant Principal",
        text: "Changed some of my learners' love of writing from 0 to 100. Debra Burson, @debraburson , Year 5/6 teacher, Wellington NZ",
      },
    },
    questions: {
      title: "Any Questions？",
      item_tilte_1: "What is the difference between a Teacher Plan and a School Plan?",
      item_desc_1:
        "Our Teacher Plan includes access for one educator (no student access) and use of our teacher admin tools. The School Plan is customizable in size to include an account for each student/teacher.",
      item_tilte_2: "What payment methods do you accept?",
      item_desc_2:
        "We accept all major credit and debit cards, as well as Paypal. Please contact us to discuss purchase order options.",
      item_tilte_3: "Is my students’ work safe?",
      item_desc_3:
        "Students’ work may be published privately to the teacher or publicly on site. All public stories and comments are reviewed by our professional moderation team. Upon approval, work becomes available for viewing in the Storybird Public Library.",
      has_more: "I have more questions!",
      email_us: "Email us at",
    },
  },
  buy: {
    title: "Place Your Order",
    title_renewal: "Renew Your Order",
    number_limit: "*You cannot purchase a school plan with fewer people than currently available.",
    desc: "Use our tool below to find a price including volume discounts",
    desc_renewal: "Select the number of accounts to receive pricing information.",
    next: "Next",
    pre: "Pre",
    requirements: {
      title: "Account Requirements",
      desc: "Drag to select the number of people (Total number of teachers and students.)",
    },
    information: {
      title: "School Information",
      basic_information: "Basic Information",
      school_name: "School Name",
      country: "Country",
      address: "Address",
      city: "City",
      state: "State",
      zip: "Zip / Postal Code",
      phone: "Phone number",
      teaching_information: "Teaching Information",
      first_name: "First Name",
      last_name: "Last Name",
      job_title: "Job Title",
      school_email: "School Email Address",
      hear_about_us: "How did you hear about us?",
      student_number: "Number of Students",
      start_date: "Activate date for free trial",
    },
    form_rules: {
      name: "Please input school name",
      country: "Please select country",
      address: "Please input address",
      city: "Please input city",
      state: "Please input state",
      zip: "Please input Zip / Postal Code",
      phone: "Please input phone number",
      firstName: "Please input first name",
      lastName: "Please input last name",
      jobTitle: "Please input job title",
      schoolEmail: "Please input school email address",
      hear: "Please select",
    },
    how_use: {
      title: "How will you use Storybird?",
      item_1: "Elementary",
      item_2: "Middle School",
      item_3: "High School",
      item_4: "ESL",
      item_5: "Remote Learning",
      item_6: "Language Arts",
      item_7: "Other",
      talk_us: "Is there additional information you'd like to share?",
    },
    pay_order: {
      title: "Pay order",
      student_accounts: "Accounts",
      original_price: "Original Price",
      discount: "Discount",
      total_price: "Total Price",
      buy_tips:
        "Accounts include student accounts and teacher accounts. For example, if you have 1 teacher and 10 students, you need to buy 11 accounts.",
      buy_tips_1: "The validity period of plan is for a full year.",
    },
    buy_now: "Buy now",
    free_trial_title: "Apply for School Plan Free trial",
    free_trial_received:
      "We have received your application and will get back to you shortly. Thank you for your patience.",
    free_trial_received_unregister:
      "An activation email has been sent to you. Please activate your account within 24 hours to complete the Free Trial application. We have received your application and will get back to you shortly. Thank you for your patience.",
    free_trial_apply: "Apply",
  },
  top_bar: {
    guides: "Tutorials",
    newBook: "New Book",
    chooseBookType: "Choose a book type",
  },
  language: {
    all: "All Language",
    english: "English",
    chinese: "Chinese",
  },
};

export default es;
